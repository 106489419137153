import { useContext } from "react";
import { useParams } from "react-router";
import { AiFillHeart, AiFillWindows } from "react-icons/ai";
import { GoBrowser } from "react-icons/go";
import { useFetch } from "../hooks/useFetch";
import { FavoritesContext } from "../context/FavoritesContext";

// styles
import styles from "./Details.module.css";

import Spinner from "../components/ui/Spinner";

const Details = () => {
  const { id } = useParams();
  const {
    data: game,
    isPending,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/game?sid=76N9I&gid=${id}`, {
    id,
  });

  const { gameIsFavorite, addToFavorite } = useContext(FavoritesContext);

  return (
    <section className={styles.game_details}>
      {isPending && <Spinner />}
      {error && <p>{error}</p>}
      {game && (
        <article className={styles.article}>
          <iframe className={styles.embedContainer} src={game.url} width="480" height="320" scrolling="no"></iframe>
          <img
            className={styles.thumbnail}
            src={game.thumbnailUrl}
            alt={game.title}
          />
          <h1 className={styles.title}>About {game.title}</h1>
          {game.description.split(/(\r\n|\r|\n)/gi).map((paragraph, idx) => (
            <p key={idx} style={{ margin: "20px 0", fontSize: "15px" }}>
              {paragraph}
            </p>
          ))}

          <h3>Additional Information</h3>
          <ul className={styles.info_list}>
            <li>
              <span className="text-muted">Title</span>
              <p>{game.title}</p>
            </li>
            <li>
              <span className="text-muted">Developer</span>
              <p>{game.author}</p>
            </li>
            <li>
              <span className="text-muted">Release Date</span>
              <p>{game.creation}</p>
            </li>
            <li>
              <span className="text-muted">Category</span>
              <p>{game.category}</p>
            </li>
          </ul>
          <button
            onClick={() => addToFavorite(game)}
            className={`btn ${styles.btn_favorite}`}
            title={
              gameIsFavorite(game.id)
                ? "Remove from favorites"
                : "Add to favorites"
            }
          >
            <AiFillHeart
              className={styles.heart_icon}
              color={gameIsFavorite(game.id) ? "#ff0000" : "#fff"}
            />
          </button>
        </article>
      )}
    </section>
  );
};

export default Details;
